function $_(a) {
  return document.getElementById(a);
}
function $F_(a) {
  var b = $_(a);
  switch (b.type) {
    case "select-one":
      return b.options[b.selectedIndex].value;
      break;
    case "radio":
    case "checkbox":
      return b.checked;
      break;
    case "text":
    case "password":
    case "textarea":
    case "hidden":
      return b.value;
      break;
    default:
      return b.innerHTML;
  }
}
function killEvent(e, d, b, a) {
  if (e.removeEventListener) {
    e.removeEventListener(d, b, a);
    return true;
  } else {
    if (e.detachEvent) {
      var c = e.detachEvent("on" + d, b);
      return c;
    } else {
      e["on" + d] = null;
    }
  }
}
function addEventMy(e, d, b, a) {
  if (e.addEventListener) {
    e.addEventListener(d, b, a);
    return true;
  } else {
    if (e.attachEvent) {
      var c = e.attachEvent("on" + d, b);
      return c;
    } else {
      e["on" + d] = b;
    }
  }
}
function highlightField(a) {
  jQuery("#" + a).addClass("fielderror");
}
function unhighlightField(b) {
  var c = document.forms[b];
  var a = c.length;
  for (i = 0; i < a; i++) {
    if (c.elements[i].type == "button" || c.elements[i].type == "submit" || c.elements[i].type == "radio" || c.elements[i].type == "hidden") {
      continue;
    }
    jQuery(c.elements[i]).removeClass("fielderror");
  }
}
function isEmpty(b) {
  var a = /\S/;
  return (ret = a.test(b) ? 0 : 1);
}
function checkMail(b) {
  var a = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return (ret = a.test(b) ? 1 : 0);
}
function Equal(b, a) {
  return b == a;
}
function validateRegistrationForm(c, k) {
  var j = new Array();
  var f = new Array();
  var e = new Array();
  var l = new Array();
  var d = 0;
  if (register_field_require.title) {
    j[d] = "title";
    f[d] = "notn";
    e[d] = "";
    l[d] = "";
    d++;
  }
  if (register_field_require.f_name) {
    j[d] = "f_name";
    f[d] = "nem";
    e[d] = "";
    l[d] = "";
    d++;
  }
  if (register_field_require.l_name) {
    j[d] = "l_name";
    f[d] = "nem";
    e[d] = "";
    l[d] = "";
    d++;
  }
  if (register_field_require.m_name) {
    j[d] = "m_name";
    f[d] = "nem";
    e[d] = "";
    l[d] = "";
    d++;
  }
  if (register_field_require.firma_name) {
    j[d] = "firma_name";
    f[d] = "nem";
    e[d] = "";
    l[d] = "";
    d++;
  }
  if (register_field_require.client_type) {
    j[d] = "client_type";
    f[d] = "notn";
    e[d] = "";
    l[d] = "";
    d++;
  }
  if ((document.forms[k].client_type && document.forms[k].client_type.value == "2") || !document.forms[k].client_type) {
    if (register_field_require.firma_code) {
      j[d] = "firma_code";
      f[d] = "nem";
      e[d] = "";
      l[d] = "";
      d++;
    }
    if (register_field_require.tax_number) {
      j[d] = "tax_number";
      f[d] = "nem";
      e[d] = "";
      l[d] = "";
      d++;
    }
  }
  if (register_field_require.email) {
    j[d] = "email";
    f[d] = "em";
    e[d] = "";
    l[d] = "";
    d++;
  }
  if (register_field_require.email2) {
    j[d] = "email";
    f[d] = "eqne";
    e[d] = "email2";
    l[d] = "";
    d++;
  }
  if (register_field_require.birthday) {
    j[d] = "birthday";
    f[d] = "nem";
    e[d] = "";
    l[d] = "";
    d++;
  }
  if (register_field_require.home) {
    j[d] = "home";
    f[d] = "nem";
    e[d] = "";
    l[d] = "";
    d++;
  }
  if (register_field_require.apartment) {
    j[d] = "apartment";
    f[d] = "nem";
    e[d] = "";
    l[d] = "";
    d++;
  }
  if (register_field_require.street) {
    j[d] = "street";
    f[d] = "nem";
    e[d] = "";
    l[d] = "";
    d++;
  }
  if (register_field_require.street_nr) {
    j[d] = "street_nr";
    f[d] = "nem";
    e[d] = "";
    l[d] = "";
    d++;
  }
  if (register_field_require.zip) {
    j[d] = "zip";
    f[d] = "zip";
    e[d] = "";
    l[d] = "";
    d++;
  }
  if (register_field_require.city) {
    j[d] = "city";
    f[d] = "nem";
    e[d] = "";
    l[d] = "";
    d++;
  }
  if (register_field_require.state) {
    j[d] = "state";
    f[d] = "nem";
    e[d] = "";
    l[d] = "";
    d++;
  }
  if (register_field_require.country) {
    j[d] = "country";
    f[d] = "notn";
    e[d] = "";
    l[d] = "";
    d++;
  }
  if (register_field_require.phone) {
    j[d] = "phone";
    f[d] = "nem";
    e[d] = "";
    l[d] = "";
    d++;
  }
  if (register_field_require.mobil_phone) {
    j[d] = "mobil_phone";
    f[d] = "nem";
    e[d] = "";
    l[d] = "";
    d++;
  }
  if (register_field_require.fax) {
    j[d] = "fax";
    f[d] = "nem";
    e[d] = "";
    l[d] = "";
    d++;
  }
  if (register_field_require.ext_field_1) {
    j[d] = "ext_field_1";
    f[d] = "nem";
    e[d] = "";
    l[d] = "";
    d++;
  }
  if (register_field_require.ext_field_2) {
    j[d] = "ext_field_2";
    f[d] = "nem";
    e[d] = "";
    l[d] = "";
    d++;
  }
  if (register_field_require.ext_field_3) {
    j[d] = "ext_field_3";
    f[d] = "nem";
    e[d] = "";
    l[d] = "";
    d++;
  }
  if (register_field_require.u_name) {
    j[d] = "u_name";
    f[d] = "nem";
    e[d] = "";
    l[d] = "";
    d++;
  }
  if (register_field_require.password && register_field_require.password_2) {
    j[d] = "password";
    f[d] = "eqne";
    e[d] = "password_2";
    l[d] = "";
    d++;
  } else {
    if (register_field_require.password) {
      j[d] = "password";
      f[d] = "nem";
      e[d] = "";
      l[d] = "";
      d++;
    }
  }
  if (register_field_require.privacy_statement) {
    j[d] = "privacy_statement";
    f[d] = "chk";
    e[d] = "";
    l[d] = "";
    d++;
  }
  var b = new validateForm(k, j, f, e, l, 2);
  var g = b.validate() ? 1 : 0;
  if (!g) {
    return false;
  } else {
    function h(m) {
      if (m != 1) {
        alert(m);
        return false;
      } else {
        document.forms[k].submit();
      }
    }
    var a = {username: jQuery("#u_name").val(), email: jQuery("#email").val()};
    jQuery.get(c, a, h);
    return false;
  }
}
function validateCheckoutAdressForm(h, g) {
  var a = 2;
  var f = new Array();
  var e = new Array();
  var d = new Array();
  var j = new Array();
  var c = 0;
  if (register_field_require.title) {
    f[c] = "title";
    e[c] = "notn";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.f_name) {
    f[c] = "f_name";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.l_name) {
    f[c] = "l_name";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.m_name) {
    f[c] = "m_name";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.firma_name) {
    f[c] = "firma_name";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.client_type) {
    f[c] = "client_type";
    e[c] = "notn";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if ((document.forms[g].client_type && document.forms[g].client_type.value == "2") || !document.forms[g].client_type) {
    if (register_field_require.firma_code) {
      f[c] = "firma_code";
      e[c] = "nem";
      d[c] = "";
      j[c] = "";
      c++;
    }
    if (register_field_require.tax_number) {
      f[c] = "tax_number";
      e[c] = "nem";
      d[c] = "";
      j[c] = "";
      c++;
    }
  }
  if (register_field_require.email) {
    f[c] = "email";
    e[c] = "em";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.email2) {
    f[c] = "email";
    e[c] = "eqne";
    d[c] = "email2";
    j[c] = "";
    c++;
  }
  if (register_field_require.birthday) {
    f[c] = "birthday";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.home) {
    f[c] = "home";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.apartment) {
    f[c] = "apartment";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.street) {
    f[c] = "street";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.street_nr) {
    f[c] = "street_nr";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.zip) {
    f[c] = "zip";
    e[c] = "zip";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.city) {
    f[c] = "city";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.state) {
    f[c] = "state";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.country) {
    f[c] = "country";
    e[c] = "notn";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.phone) {
    f[c] = "phone";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.mobil_phone) {
    f[c] = "mobil_phone";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.fax) {
    f[c] = "fax";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.ext_field_1) {
    f[c] = "ext_field_1";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.ext_field_2) {
    f[c] = "ext_field_2";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.ext_field_3) {
    f[c] = "ext_field_3";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.privacy_statement) {
    f[c] = "privacy_statement";
    e[c] = "chk";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if ($_("delivery_adress_2")) {
    if ($F_("delivery_adress_2")) {
      if (register_field_require.d_title) {
        f[c] = "d_title";
        e[c] = "notn";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_f_name) {
        f[c] = "d_f_name";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_l_name) {
        f[c] = "d_l_name";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_m_name) {
        f[c] = "d_m_name";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_firma_name) {
        f[c] = "d_firma_name";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_email) {
        f[c] = "d_email";
        e[c] = "em";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_birthday) {
        f[c] = "d_birthday";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_home) {
        f[c] = "d_home";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_apartment) {
        f[c] = "d_apartment";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_street) {
        f[c] = "d_street";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_street_nr) {
        f[c] = "d_street_nr";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_zip) {
        f[c] = "d_zip";
        e[c] = "zip";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_city) {
        f[c] = "d_city";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_state) {
        f[c] = "d_state";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_country) {
        f[c] = "d_country";
        e[c] = "notn";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_phone) {
        f[c] = "d_phone";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_mobil_phone) {
        f[c] = "d_mobil_phone";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_fax) {
        f[c] = "d_fax";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_ext_field_1) {
        f[c] = "d_ext_field_1";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_ext_field_2) {
        f[c] = "d_ext_field_2";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_ext_field_3) {
        f[c] = "d_ext_field_3";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
    }
  }
  var b = new validateForm(g, f, e, d, j, a);
  return (error = b.validate());
}
function validateEditAccountForm(h, g) {
  var a = 2;
  var f = new Array();
  var e = new Array();
  var d = new Array();
  var j = new Array();
  var c = 0;
  if (register_field_require.title) {
    f[c] = "title";
    e[c] = "notn";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.f_name) {
    f[c] = "f_name";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.l_name) {
    f[c] = "l_name";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.m_name) {
    f[c] = "m_name";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.firma_name) {
    f[c] = "firma_name";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.client_type) {
    f[c] = "client_type";
    e[c] = "notn";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if ((document.forms[g].client_type && document.forms[g].client_type.value == "2") || !document.forms[g].client_type) {
    if (register_field_require.firma_code) {
      f[c] = "firma_code";
      e[c] = "nem";
      d[c] = "";
      j[c] = "";
      c++;
    }
    if (register_field_require.tax_number) {
      f[c] = "tax_number";
      e[c] = "nem";
      d[c] = "";
      j[c] = "";
      c++;
    }
  }
  if (register_field_require.email) {
    f[c] = "email";
    e[c] = "em";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.birthday) {
    f[c] = "birthday";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.home) {
    f[c] = "home";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.apartment) {
    f[c] = "apartment";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.street) {
    f[c] = "street";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.street_nr) {
    f[c] = "street_nr";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.zip) {
    f[c] = "zip";
    e[c] = "zip";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.city) {
    f[c] = "city";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.state) {
    f[c] = "state";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.country) {
    f[c] = "country";
    e[c] = "notn";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.phone) {
    f[c] = "phone";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.mobil_phone) {
    f[c] = "mobil_phone";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.fax) {
    f[c] = "fax";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.ext_field_1) {
    f[c] = "ext_field_1";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.ext_field_2) {
    f[c] = "ext_field_2";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.ext_field_3) {
    f[c] = "ext_field_3";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.password) {
    f[c] = "password";
    e[c] = "nem";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if (register_field_require.password_2) {
    f[c] = "password_2";
    e[c] = "eqne";
    d[c] = "password";
    j[c] = "";
    c++;
  }
  if (register_field_require.privacy_statement) {
    f[c] = "privacy_statement";
    e[c] = "chk";
    d[c] = "";
    j[c] = "";
    c++;
  }
  if ($_("delivery_adress_2")) {
    if ($F_("delivery_adress_2")) {
      if (register_field_require.d_title) {
        f[c] = "d_title";
        e[c] = "notn";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_f_name) {
        f[c] = "d_f_name";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_l_name) {
        f[c] = "d_l_name";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_m_name) {
        f[c] = "d_m_name";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_firma_name) {
        f[c] = "d_firma_name";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_email) {
        f[c] = "d_email";
        e[c] = "em";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_birthday) {
        f[c] = "d_birthday";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_home) {
        f[c] = "d_home";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_apartment) {
        f[c] = "d_apartment";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_street) {
        f[c] = "d_street";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_street_nr) {
        f[c] = "d_street_nr";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_zip) {
        f[c] = "d_zip";
        e[c] = "zip";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_city) {
        f[c] = "d_city";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_state) {
        f[c] = "d_state";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_country) {
        f[c] = "d_country";
        e[c] = "notn";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_phone) {
        f[c] = "d_phone";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_mobil_phone) {
        f[c] = "d_mobil_phone";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_fax) {
        f[c] = "d_fax";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_ext_field_1) {
        f[c] = "d_ext_field_1";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_ext_field_2) {
        f[c] = "d_ext_field_2";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
      if (register_field_require.d_ext_field_3) {
        f[c] = "d_ext_field_3";
        e[c] = "nem";
        d[c] = "";
        j[c] = "";
        c++;
      }
    }
  }
  var b = new validateForm(g, f, e, d, j, a);
  return (error = b.validate());
}
function validateFormAdvancedSearch(b) {
  var e = new Array("date_from", "date_to", "price_from", "price_to");
  var f = new Array("date|em", "date|em", "fl|em|0", "fl|em");
  var a = new Array("", "");
  var d = new Array("", "");
  var c = new validateForm(b, e, f, a, d, 2);
  error = c.validate();
  return error;
}
function validateReviewForm(b) {
  var e = new Array("review_user_name", "review_user_email", "review_review");
  var f = new Array("nem", "em", "nem");
  var a = new Array("", "");
  var d = new Array("", "");
  var c = new validateForm(b, e, f, a, d, 2);
  error = c.validate();
  return error;
}
function checkAGBAndNoReturn(b, a) {
  result1 = result2 = true;
  if (b == "1") {
    result1 = checkAGB();
  }
  if (a == "1") {
    result2 = checkNoReturn();
  }
  if (result1 && result2) {
    return true;
  } else {
    return false;
  }
}
function checkAGB() {
  if ($_("agb").checked) {
    jQuery(".row_agb").removeClass("fielderror");
    return true;
  } else {
    jQuery(".row_agb").addClass("fielderror");
    jQuery("#agb").focus();
    return false;
  }
}
function checkNoReturn() {
  if ($_("no_return").checked) {
    jQuery(".row_no_return").removeClass("fielderror");
    return true;
  } else {
    jQuery(".row_no_return").addClass("fielderror");
    jQuery("#no_return").focus();
    return false;
  }
}
var activePaymentMethod = "";
function showPaymentForm(a) {
  activePaymentMethod = a;
  jQuery("*[id^='tr_payment_']").hide();
  jQuery("#tr_payment_" + a).show();
}
function isInt_5_8(b) {
  var a = /^(\d){5,8}$/;
  return (ret = a.test(b) ? 1 : 0);
}
function validateShippingMethods() {
  var c = $_("table_shippings");
  var a = c.getElementsByTagName("input");
  for (var b = 0; b < a.length; b++) {
    if (a[b].type != "radio") {
      continue;
    }
    if (a[b].checked) {
      return true;
    }
  }
  return false;
}
function showShippingForm(a) {
  jQuery("div.shipping_form").removeClass("shipping_form_active");
  jQuery("#shipping_form_" + a).addClass("shipping_form_active");
}
function hideElement(a) {
  $_(a).style.display = "none";
}
function disableElement(a) {
  $_(a).disabled = true;
}
function submitListProductFilterSortDirection() {
  $_("orderby").value = $_("orderby").value ^ 1;
  submitListProductFilters();
}
function submitListProductFilters() {
  $_("sort_count").submit();
}
function clearProductListFilter() {
  jQuery("#manufacturers").val("0");
  jQuery("#categorys").val("0");
  jQuery("#price_from").val("");
  jQuery("#price_to").val("");
  submitListProductFilters();
}
var joomshoppingVideoHtml5 = 0;
var joomshoppingVideoHtml5Type = "";
function showVideo(b, d, a) {
  var f = jQuery("#" + b).attr("href");
  jQuery(".video_full").hide();
  jQuery("#hide_" + b).attr("href", f);
  jQuery("a.lightbox").hide();
  jQuery("#main_image").hide();
  jQuery(".product_label").hide();
  jQuery("#videoshophtml5").remove();
  if (!joomshoppingVideoHtml5) {
    jQuery("#hide_" + b).show();
    jQuery("#hide_" + b).media({width: d, height: a});
  } else {
    var c = {id: "videoshophtml5", src: f, width: d, height: a, controls: true};
    if (joomshoppingVideoHtml5Type != "") {
      c.type = joomshoppingVideoHtml5Type;
    }
    var e = jQuery("<video />", c);
    e.appendTo(jQuery(".image_middle"));
  }
}
function showVideoCode(a) {
  jQuery(".video_full:not(#hide_" + a + ")").hide();
  jQuery("a.lightbox").hide();
  jQuery("#main_image").hide();
  jQuery(".product_label").hide();
  jQuery("#videoshophtml5").remove();
  jQuery("#hide_" + a).show();
}
/*function showImage(a) {
  jQuery(".video_full").hide();
  jQuery("#videoshophtml5").remove();
  jQuery("a.lightbox").hide();
  jQuery("#main_image_full_" + a).show();
  jQuery(".product_label").show();
}*/
function playMusic(a) {
  jQuery("#" + a).media({width: 100, height: 20, autoplay: true});
}
function showHideReview() {
  jQuery("#jshop_review_write").show();
}
function formatprice(a) {
  if (typeof decimal_count === "undefined") {
    decimal_count = 2;
  }
  if (typeof decimal_symbol === "undefined") {
    decimal_symbol = ".";
  }
  a = a.toFixed(decimal_count).toString();
  a = a.replace(".", decimal_symbol);
  res = format_currency.replace("Symb", currency_code);
  res = res.replace("00", a);
  return res;
}
var prevAjaxHandler = null;
var reloadAttribEvents = [];
var extdataurlupdateattr = {};
function reloadAttribSelectAndPrice(c) {
  var b = jQuery("#product_id").val();
  var f = jQuery("#quantity").val();
  var d = {};
  d.change_attr = c;
  d.qty = f;
  for (var a = 0; a < attr_list.length; a++) {
    var e = attr_list[a];
    d["attr[" + e + "]"] = attr_value[e];
  }
  for (extdatakey in extdataurlupdateattr) {
    d[extdatakey] = extdataurlupdateattr[extdatakey];
  }
  if (prevAjaxHandler) {
    prevAjaxHandler.abort();
  }
  prevAjaxHandler = jQuery.getJSON(urlupdateprice, d, function (q) {
    var p = 0;
    for (var n = 0; n < attr_list.length; n++) {
      var h = attr_list[n];
      if (p) {
        jQuery("#block_attr_sel_" + h).html(q["id_" + h]);
      }
      if (h == c) {
        p = 1;
      }
    }
    jQuery("#block_price").html(q.price);
    jQuery("#pricefloat").val(q.pricefloat);
    if (q.basicprice) {
      jQuery("#block_basic_price").html(q.basicprice);
    }
    for (key in q) {
      if (key.substr(0, 3) == "pq_") {
        jQuery("#pricelist_from_" + key.substr(3)).html(q[key]);
      }
    }
    if (q.available == "0") {
      jQuery("#not_available").html(translate_not_available);
    } else {
      jQuery("#not_available").html("");
    }
    if (q.displaybuttons == "0") {
      jQuery(".productfull .prod_buttons").hide();
    } else {
      jQuery(".productfull .prod_buttons").show();
    }
    if (q.ean) {
      jQuery("#product_code").html(q.ean);
    }
    if (q.manufacturer_code) {
      jQuery("#manufacturer_code").html(q.manufacturer_code);
    }
    if (q.weight) {
      jQuery("#block_weight").html(q.weight);
    }
    if (q.pricedefault) {
      jQuery("#pricedefault").html(q.pricedefault);
    }
    if (q.qty) {
      jQuery("#product_qty").html(q.qty);
    }
    if (q.oldprice) {
      jQuery("#old_price").html(q.oldprice);
      jQuery(".old_price").show();
    } else {
      jQuery(".old_price").hide();
    }
    if (q.images && q.images.length > 0) {
      var o = q.images.length;
      var g = "";
      var k = "";
      var l = "";
      if (typeof jshop_product_hide_zoom_image === "undefined") {
        jshop_product_hide_zoom_image = 0;
      }
      if (!jshop_product_hide_zoom_image) {
        l = ' <div class="text_zoom"><img alt="zoom" src="' + liveimgpath + '/search.png" /> ' + translate_zoom_image + "</div>";
      }
      // for (var m = 0; m < o; m++) {
      //   g += '<img class="jshop_img_thumb" src="' + liveproductimgpath + "/thumb_" + q.images[m] + '" onclick = "showImage(' + m + ')" />';
      //   tmp = 'style="display:none"';
      //   if (m == 0) {
      //     tmp = "";
      //   }
      //   k += '<a class="lightbox" id="main_image_full_' + m + '" href="' + liveproductimgpath + "/full_" + q.images[m] + '" ' + tmp + '><img id="main_image_' + m + '" src="' + liveproductimgpath + "/" + q.images[m] + '" />' + l + "</a>";
      // }
      if (q.displayimgthumb == "1") {
        jQuery("#list_product_image_thumb").html(g);
      } else {
        jQuery("#list_product_image_thumb").html("");
      }
      jQuery("#list_product_image_middle").html(k);
      initJSlightBox();
    }
    if (q.block_image_thumb || q.block_image_middle) {
      jQuery("#list_product_image_thumb").html(q.block_image_thumb);
      jQuery("#list_product_image_middle").html(q.block_image_middle);
      initJSlightBox();
    }
    if (typeof q.demofiles != "undefined") {
      jQuery("#list_product_demofiles").html(q.demofiles);
    }
    if (q.showdeliverytime) {
      if (q.showdeliverytime == "0") {
        jQuery(".productfull .deliverytime").hide();
      } else {
        jQuery(".productfull .deliverytime").show();
      }
    }
    jQuery.each(reloadAttribEvents, function (j, r) {
      r.call(this, q);
    });
    reloadAttrValue();
  });
}
function setAttrValue(b, a) {
  attr_value[b] = a;
  reloadAttribSelectAndPrice(b);
  reloadAttribImg(b, a);
}
function reloadAttribImg(d, b) {
  var c = "";
  var a = "";
  if (b == "0") {
    a = "";
  } else {
    if (attr_img[b]) {
      a = attr_img[b];
    } else {
      a = "";
    }
  }
  if (a == "") {
    c = liveimgpath;
    a = "blank.gif";
  } else {
    c = liveattrpath;
  }
  jQuery("#prod_attr_img_" + d).attr("src", c + "/" + a);
}
function reloadAttrValue() {
  for (var a in attr_value) {
    if (jQuery("input[name=jshop_attr_id\\[" + a + "\\]]").attr("type") == "radio") {
      attr_value[a] = jQuery("input[name=jshop_attr_id\\[" + a + "\\]]:checked").val();
    } else {
      attr_value[a] = jQuery("#jshop_attr_id" + a).val();
    }
  }
}
function reloadPrices() {
  var a = jQuery("#quantity").val();
  if (a != "") {
    reloadAttribSelectAndPrice(0);
  }
}
function showHideFieldFirm(a) {
  if (a == "2") {
    jQuery("#tr_field_firma_code").show();
    jQuery("#tr_field_tax_number").show();
  } else {
    jQuery("#tr_field_firma_code").hide();
    jQuery("#tr_field_tax_number").hide();
  }
}
function updateSearchCharacteristic(a, b) {
  function d(e) {
    jQuery("#list_characteristics").html(e);
  }
  var c = {category_id: b};
  jQuery.get(a, c, d);
}
function registrationTestPassword(a) {
  jQuery.ajax({type: "POST", url: urlcheckpassword, data: {pass: a}, dataType: "json"}).done(function (b) {
    if (b.msg) {
      jQuery("#reg_test_password").html(b.msg);
      jQuery("#reg_test_password").addClass("fielderrormsg");
      jQuery("#reg_test_password").removeClass("fieldpassok");
    } else {
      jQuery("#reg_test_password").html("");
      jQuery("#reg_test_password").removeClass("fielderrormsg");
      jQuery("#reg_test_password").addClass("fieldpassok");
    }
  });
}
jQuery(function () {
  jQuery(".registrationTestPassword").keyup(function () {
    registrationTestPassword(this.value);
  });
});
